import React from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import { CustomToastProps, NotifyProps } from './Toaster.types';

const DEFAULT_TITLE = {
  success: 'Successfully saved!',
  error: 'Something went wrong!',
};

const CustomToast = ({ title, message, type }: CustomToastProps) => (
  <>
    <div className="flex items-start">
      <div className="flex-shrink-0">
        {type === 'success' && (
          <CheckCircleIcon
            className="w-6 h-6 text-green-400"
            aria-hidden="true"
          />
        )}
        {type === 'error' && (
          <XCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />
        )}
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-gray-900">
          {title || DEFAULT_TITLE[type]}
        </p>
        <p className="mt-1 text-sm text-gray-500">{message}</p>
      </div>
    </div>
  </>
);

export const notify = ({
  title = '',
  message,
  type = 'success',
}: NotifyProps) =>
  toast(<CustomToast title={title} message={message} type={type} />);

export const Toaster = () => (
  <ToastContainer
    autoClose={5000}
    hideProgressBar
    newestOnTop
    draggable={false}
    pauseOnHover
  />
);
